import { UserFlags } from '../../constants/flags';
import { EvaluationResult } from '../../services/flagr';
import { UserAgentEnum, parseUserAgent } from '../../services/useragent';

export function determineUseNativeBehavior(role: string | undefined, evaluationResults: EvaluationResult[] | undefined) {
	const userAgent = parseUserAgent();
	const enableNativeAppFlag = evaluationResults?.find(({ flagKey }) => flagKey === UserFlags.EnableNativeApp);
	const enableNativeApp = enableNativeAppFlag?.variantKey === 'on';
	const isNative =
		role === 'Student' &&
		enableNativeApp &&
		(userAgent === UserAgentEnum.IOS || userAgent === UserAgentEnum.ANDROID || userAgent === UserAgentEnum.KINDLE);
	return isNative;
}

export function determineUseNativeAppBehavior(role: string | undefined) {
	const isIlMobileWebView = window.navigator.userAgent.includes('ILMobileWebView');
	const isNativeApp = role === 'Student' && isIlMobileWebView;
	return isNativeApp;
}

import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import './App.scss';
import Loader from './components/Loader/Loader';
import { fetchingGlobalFlags } from './redux/modules/app';
import { RootState } from './redux/root';
import { selectAccessToken, selectGlobalFlagsLoaded, selectRole, selectTenant } from './redux/selectors/app';
import getLaunchUrl from './services/productLaunch';
import { Products } from './constants/products';
import PORTAL_URLS from './constants/portalUrls';
import { getEnvironment } from './services/network';
import URLS from './constants/urls';
import { Environment } from './constants/environments';
import { ApiServices } from './constants/apiServices';
import { UserFlags } from './constants/flags';
import useFlag from './hooks/useFlagr';

// Lazy load components
const ErrorScreen = lazy(() => import('./components/ErrorScreen/ErrorScreen'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword/ForgotPassword'));
const ForgotSiteCode = lazy(() => import('./components/ForgotSiteCode/ForgotSiteCode'));
const Login = lazy(() => import('./components/Login/Login'));
const ProductSelectionScreen = lazy(() => import('./components/ProductSelectionScreen/ProductSelectionScreen'));
const ResetPassword = lazy(() => import('./components/ResetPassword/ResetPassword'));
const SignOut = lazy(() => import('./components/SignOut/SignOut'));

interface AppProps {
	accessToken?: string;
	flagrActions: {
		initialize: () => void;
	};
	role?: string;
	tenant?: string;
	flagsLoaded: boolean;
}

function App({ accessToken, flagrActions, role, tenant, flagsLoaded }: AppProps) {
	useEffect(() => {
		localStorage.removeItem('oidcSessionId');
	}, []);

	useEffect(() => {
		flagrActions.initialize();
	}, [flagrActions]);

	const EnableReadOnlyILSiteAdminRedirectToMaestro = useFlag(UserFlags.EnableReadOnlyILSiteAdminRedirectToMaestro)?.variantKey === 'on';
	localStorage.removeItem('oidcSessionId');

	//redirect ALA tenant users
	if (tenant && tenant === 'Tenant.ALA') {
		const launchUrl = getLaunchUrl(Products.MyPathPortal, PORTAL_URLS, getEnvironment(), false, undefined);
		if (launchUrl !== undefined) {
			window.location.href = launchUrl;
			return <Loader />;
		}
	}

	if (EnableReadOnlyILSiteAdminRedirectToMaestro) {
		if (accessToken && role === 'ReadOnlyILSiteAdmin') {
			const launchUrl = URLS[getEnvironment() || Environment.Production][ApiServices.Maestro];
			if (launchUrl) {
				window.location.href = launchUrl;
				return <Loader />;
			}
		}
	}

	if (accessToken && role && /^il/i.test(role)) {
		// ILSiteAdmin or ILAdmin roles.
		const launchUrl = getLaunchUrl(Products.ILE, PORTAL_URLS, getEnvironment(), false, undefined);
		if (launchUrl !== undefined) {
			window.location.href = launchUrl;
			return <Loader />;
		}
	}

	if (!flagsLoaded) {
		return <Loader />; // Wait for flags to load. This is so we can use RemoveIlAuthFallback flag from first "real" render of login screen.
	}

	return (
		<Suspense fallback={<Loader />}>
			<Switch>
				<Route path="/error" component={ErrorScreen} />
				<Route path="/forgotSiteCode" component={ForgotSiteCode} />
				<Route path="/productSelection" component={ProductSelectionScreen} />
				<Route path="/resetPassword/:token" component={ResetPassword} />
				<Route path="/resetPassword" component={ForgotPassword} />
				<Route path="/signin" component={Login} />
				<Route path="/signout" component={SignOut} />
				<Route path="/">
					<Redirect to="/signin" />
				</Route>
			</Switch>
		</Suspense>
	);
}

const mapStateToProps = (state: RootState) => ({
	accessToken: selectAccessToken(state),
	role: selectRole(state),
	tenant: selectTenant(state),
	flagsLoaded: selectGlobalFlagsLoaded(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	flagrActions: bindActionCreators({ initialize: fetchingGlobalFlags }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Environment } from '../../constants/environments';
import { Products } from '../../constants/products';
import Auth from '../../services/auth';
import { getLaunchUrl, getNativeLaunchUrl } from '../../services/productLaunch';
import { log } from '../../services/logger';
import { Dictionary } from '../../types/dictionary';
import { UrlMap } from '../../types/urlMap';
import { selectUser } from '../selectors/app';
import { AppState } from './app';
import { LoggingProducts } from '../../constants/products';
import { LoginTypes } from '../../constants/loginTypes';
import { selectSiteCode, selectLoginType } from '../selectors/loginForm';
import { RootStateOrAny } from 'react-redux';
import Cookies from 'js-cookie';
import { caliperSessionEvent } from './caliper';
import { selectUserFlagEvaluationResults } from '../selectors/productSelection';
import { determineUseNativeAppBehavior, determineUseNativeBehavior } from './native';

/**
 * Action creators
 */

export const abortLaunchUnityProduct = createAction('universal-login-page/productLauncher/ABORT_LAUNCH_UNITY_PRODUCT');
export const launchProduct = createAction(
	'universal-login-page/productLauncher/LAUNCH_PRODUCT_REQUEST_BEFORE_CALIPER',
	(
		product: Products | string,
		urls: UrlMap,
		environment: Environment,
		addQuery: boolean,
		productQueryStrings: Dictionary<string>,
		isStudent: boolean
	) => ({
		payload: {
			product,
			urls,
			environment,
			addQuery,
			productQueryStrings,
			isStudent
		}
	})
);
export const launchProductAfterCaliper = createAction(
	'universal-login-page/productLauncher/LAUNCH_PRODUCT_REQUEST_AFTER_CALIPER',
	(
		product: Products | string,
		urls: UrlMap,
		environment: Environment,
		addQuery: boolean,
		productQueryStrings: Dictionary<string>,
		isStudent: boolean
	) => ({
		payload: {
			product,
			urls,
			environment,
			addQuery,
			productQueryStrings,
			isStudent
		}
	})
);
export const launchProductAtUrl = createAction<string | undefined>('universal-login-page/productLauncher/LAUNCH_PRODUCT_AT_URL');
export const launchProductAtUrlComplete = createAction('universal-login-page/productLauncher/LAUNCH_PRODUCT_AT_URL_COMPLETE');
export const requestOneTimeToken = createAction<string>('universal-login-page/productLauncher/REQUEST_OTT');
export const requestOneTimeTokenComplete = createAction('universal-login-page/productLauncher/REQUEST_OTT_COMPLETE');
export const requestOneTimeTokenError = createAction<Response | Error | object>('universal-login-page/productLauncher/REQUEST_OTT_ERROR');
/**
 * Reducer
 */

export interface ProductLauncherState {
	requestingOneTimeToken: boolean;
	requestOneTimeTokenError?: Response | Error | object;
}

const initialState: ProductLauncherState = {
	requestingOneTimeToken: false
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(requestOneTimeToken, (state, action) => ({
			...state,
			requestingOneTimeToken: true,
			requestOneTimeTokenError: undefined
		}))
		.addCase(requestOneTimeTokenComplete, (state, action) => ({
			...state,
			requestingOneTimeToken: false
		}))
		.addCase(requestOneTimeTokenError, (state, { payload }) => ({
			...state,
			requestingOneTimeToken: false,
			requestOneTimeTokenError: payload
		}))
);

export default reducer;

/**
 * Epics
 */

export function launchProductAtUrlEpic(action$: Observable<PayloadAction<string>>) {
	return action$.pipe(
		ofType(launchProductAtUrl.type),
		tap(({ payload: url }) => {
			window.location.href = url;
		}),
		map(() => launchProductAtUrlComplete())
	);
}

export function launchProductEpic(
	action$: Observable<
		PayloadAction<{
			product: Products;
			urls: UrlMap;
			environment: Environment;
			addQuery: boolean;
			productQueryStrings: Dictionary<string>;
			isStudent: boolean;
		}>
	>,
	state$: StateObservable<RootStateOrAny>
) {
	return action$.pipe(
		ofType(launchProduct.type),
		withLatestFrom(state$),
		map(([{ payload }, state]) => {
			const { addQuery, environment, isStudent, product, productQueryStrings, urls } = payload;
			return caliperSessionEvent(product, urls, environment, addQuery, productQueryStrings, isStudent);
		})
	);
}

export function launchProductAfterCaliperEpic(
	action$: Observable<
		PayloadAction<{
			product: Products;
			urls: UrlMap;
			environment: Environment;
			addQuery: boolean;
			productQueryStrings: Dictionary<string>;
			isStudent: boolean;
		}>
	>,
	state$: StateObservable<RootStateOrAny>
) {
	return action$.pipe(
		ofType(launchProductAfterCaliper.type),
		withLatestFrom(state$),
		switchMap(([{ payload }, state]) => {
			const { addQuery, environment, isStudent, product, productQueryStrings, urls } = payload;
			const loginType = selectLoginType(state);
			const siteCode = selectSiteCode(state);
			const { username, role } = selectUser(state) || {};
			const ssoConnectionType = Cookies.get('ssoConnectionType');
			const loginInfo = {
				Username: username,
				Type: 'Login',
				SsoConnectionType: ssoConnectionType,
				LoginType: ssoConnectionType ? LoginTypes.SSO : loginType,
				Product: LoggingProducts[product as keyof typeof LoggingProducts],
				SiteCode: siteCode,
				UserType: role
			};
			return log(loginInfo).pipe(
				catchError(err => {
					console.error(err);
					return of(); // Return empty observable so the chain can continue
				}),
				map(() => {
					let url = getLaunchUrl(product, urls, environment as Environment, addQuery, productQueryStrings);

					const isNativeAppUser = determineUseNativeAppBehavior(role);

					const takeBenchmark = product === Products.IEBenchmarkPlayerLink;

					// Ensure the user is not redirected to the native app for benchmark products if they are not a native app user
					if (takeBenchmark && !isNativeAppUser) {
						return launchProductAtUrl(url);
					}

					const evaluationResults = selectUserFlagEvaluationResults(state);

					const isNative = determineUseNativeBehavior(role, evaluationResults);

					// Update URL if native behavior is enabled and a native launch URL is available
					if (isNative) {
						const nativeLaunchUrl = getNativeLaunchUrl(product, urls, environment, true, productQueryStrings);
						if (nativeLaunchUrl) {
							url = nativeLaunchUrl;
						}
					}

					return launchProductAtUrl(url);
				})
			);
		})
	);
}

export function requestOneTimeTokenEpic(action$: Observable<PayloadAction<string>>, state$: StateObservable<{ app: AppState }>) {
	return action$.pipe(
		ofType(requestOneTimeToken.type),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { payload: url } = action;
			return Auth.requestOneTimeToken(state.app.access_token).pipe(
				switchMap(({ Code: token }) => from([requestOneTimeTokenComplete(), launchProductAtUrl(`${url}&ott=${token}`)])),
				catchError(error => {
					console.error('Failed to receive OTT:', error);
					return of(requestOneTimeTokenError(error));
				})
			);
		})
	);
}

export enum UserFlags {
	AutoLaunchGalileo = 'kd9jy5cfn9i47y613', //Auto-Launch-Assessment in Flagr
	RedirectSpanishWhenPendingAssessment = 'RedirectSpanishWhenPendingAssessment',
	EnableRobotify = 'EnableRobotify',
	EnableNativeApp = 'EnableNativeApp',
	EnableReadOnlyILSiteAdminRedirectToMaestro = 'EnableReadOnlyILSiteAdminRedirectToMaestro',
	NavbarImpersonationAndLogoutRevamp = 'NavbarImpersonationAndLogoutRevamp',
	ForceDisplayLicensedRobotify = 'ForceDisplayLicensedRobotify',
	myPathStudentRedirection = 'myPathStudentRedirection',
	TurnOffAssessmentBanner = 'TurnOffAssessmentBanner',
	FilterByAgentILMobileWebView = 'FilterByAgentILMobileWebView'
}

export enum GlobalFlags {
	EnableRobotify = 'EnableRobotify',
	ExtendedEducatorsErrorOnInvalidLogin = 'ExtendedEducatorsErrorOnInvalidLogin',
	EnableDeterministicProductOrdering = 'EnableDeterministicProductOrdering',
	RemoveIlAuthFallback = 'RemoveIlAuthFallback'
}
